<template>
    <div id="property">
        <div v-if="loading">
            <Spinner size="large" message="Loading..." line-fg-color="#000" style="padding: 5rem 0;"/>
        </div>
        <div class="property-container" v-else>
            <div class="address">
                <h1 data-aos="fade-up">
                    {{data.Address1}}
                </h1>
                <h5 data-aos="fade-up">
                    {{data.Address2}}
                </h5>
            </div>
            <div class="carousel-container">
                <VueSlickCarousel :arrows="true" :autoplay="false" :draggable="false" @afterChange="setIndex">
                    <div v-for="(slide, i) in data.Carousel" :key="i" class="carousel-image-container">
                        <img class="carousel-image" :class="getClassname(slide)" :src="url+slide.url" alt="">
                    </div>
                </VueSlickCarousel>
                <span class="desktop-only index-tracking">
                    {{currentIndex+1}} / {{data.Carousel.length}}
                </span>
            </div>
            <div data-aos="fade-up" class="details">
                <span v-if="data.Status" class="tag">
                    {{data.Status | startCase}}
                </span>
                <span>{{data.Price | priceFormat }} {{ data.Lease ? '/mo.' : '' }}</span>
                <span  v-if="data.Bedrooms || data.Bathrooms"  class="desktop-only">&middot;</span>
                <div class="inner-details">
                    <span v-if="data.Bedrooms">{{data.Bedrooms + `${$store.getters.isMobile ? ' Beds' : ' Bedrooms'}`}}</span>
                    <span v-if="data.Bedrooms">&middot;</span>
                    <span v-if="data.Bathrooms">{{data.Bathrooms + `${$store.getters.isMobile ? ' Baths' : ' Bathrooms'}`}}</span>
                </div>
                <span v-if="data.Size" class="desktop-only">&middot;</span>
                <span v-if="data.Size">{{data.Size.toLocaleString() + ' SQ.FT.'}}</span>
            </div>
            <div class="description">
                <div class="left">
                    <h1 class="title" :style="`font-size:${adpativeFontSize(longestWord(data.Address1))};line-height:${adpativeFontSize(longestWord(data.Address1))}`" data-aos="fade-up">
                        {{data.Address1}}
                    </h1>
                </div>
                <div class="right">
                    <vue-markdown data-aos="fade-up">{{data.Description}}</vue-markdown>
                </div>
            </div>
            <div class="propery-images">
                <div data-aos="fade-up" :data-aos-delay="(i%2)*400" v-for="(image, i) in data.PropertyImages" class="image-container" :key="i">
                    <img :src="url+image.url" :key="i">
                </div>
            </div>
            <div class="featured-slideshow">
                <h1 data-aos="fade-up">
                    FEATURED LISTINGS
                </h1>
                <featured-properties />
            </div>
        </div>
    </div>
</template>

<script>
import Spinner from 'vue-simple-spinner'
import VueMarkdown from 'vue-markdown'
import FeaturedProperties from '../components/FeaturedProperties';
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
    name: "property",
    components: {
        Spinner,
        VueMarkdown,
        FeaturedProperties,
        VueSlickCarousel
    },
    data(){
        return{
            title: "WALTERS | PLAXEN | Property",
            currentIndex: 0,
            loading: true,
            nodatafound: false,
            data: {},
            url: 'https://strapi.uptowncreative.io',
            images:[],
            window: {
                width: 0,
                height: 0
            },
        }
    },
    methods:{
        longestWord: function(str){
            const temp = str.split(/\s/);
            const longest = temp.reduce((a,b) => {
                if(a.length > b.length){
                    return a;
                } else {
                    return b;
                }
            })
            return longest.length
        },
        adpativeFontSize: function(num){
            const fontSize = window.innerWidth / (10 + num);
            return fontSize + 'px';
        },
        getClassname: function(image){
            const aspectRatio = image.width / image.height;

            if (aspectRatio > 1) {
                return 'landscape'
            } else {
                return 'portrait'
            }
        },
        setIndex(slideIndex){
            this.currentIndex = slideIndex;
        }
    },
    computed: {
        adaptiveHeight: function(){
            if (window.innerWidth < 1100 & window.innerHeight > 1000){
                return '40vh';
            }
            if (window.innerWidth > 576){
                return '80vh';
            } else {
                return '300px';
            }
        },
        slug(){
            return this.$route.params.slug;
        }
    },
    mounted:function(){
        this.axios.get(`https://strapi.uptowncreative.io/SWLA-Properties?Slug=${this.$route.params.slug}`)
        .then((response) => {
            this.data = response.data[0];
            this.loading = false;
        })
    },
    watch: {
        slug(){
            this.axios.get(`https://strapi.uptowncreative.io/SWLA-Properties?Slug=${this.$route.params.slug}`)
            .then((response) => {
                this.data = response.data[0];
                this.loading = false;
            })
        }
    },
    metaInfo() {
         let data = this.title; 
         return {
             title: data ? data : "Property",
             titleTemplate: '%s | WALTERS | PLAXEN',
             meta: [{
                name: 'description', 
                content: "WALTERS | PLAXEN"
            }]
         }
    },
}
</script>

<style lang="scss" scoped>
.address{
    text-align: center;
    h1{
        margin-bottom: 0;
        text-transform: uppercase;
    }
    h5{
        text-transform: uppercase;
    }
}

.index-tracking{
    position: absolute;
    margin-top: -45px;
    font-weight: 400;
    font-size: 15px;
    line-height: 31px;
    letter-spacing: 0.07em;
    left: 49.5%;
    z-index: 2;

    color: #fff;
}

.details{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin: 40px auto;
    .tag{
        margin-right: 20px;
        padding: 9px 20px 5px
    }
    span{
        font-family: poppins, sans-serif;
        font-weight: 275;
        font-size: 24px;
        line-height: 31px;
        letter-spacing: 0.07em;
        text-transform: uppercase;
    }
    .inner-details{
        display: flex;
        gap: 10px;
    }
    .title{
        text-transform: uppercase;
    }
}

.description{
    display: flex;
    margin: 100px;
    .left, .right{
        width: 50%;
        .title{
            text-transform: uppercase;
        }
    }
}

.propery-images{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    grid-auto-rows: 1fr;
}

.image-container{
    img{
        width: 100%;
        height: 100%;
        max-height: max(500px, 45vh);
        object-fit: cover;
    }
}
.featured-slideshow{
    text-align: center;
    margin-top: 50px;
}

.slick-slider{
    height: 80vh;
}

.carousel-image-container{
    height: 80vh;
    background-color: #fff;
}

.carousel-image{
    height: 100%;
    max-width: 100%;
    margin: 0 auto;
    object-fit: cover;
    &.landscape{
      width: 100%;
    }
}

::v-deep .carousel-container .slick-arrow:before{
  position: absolute;
  background-size: contain;
  width: 33.5px;
  height: 61.5px;
  display: block;
  content: '';
  transform: translateY(-44px);
}

::v-deep .carousel-container .slick-arrow.slick-next:before {
  background-image: url('/images/icon/arrow-next.svg');
  right: 12px;
}
::v-deep .carousel-container .slick-arrow.slick-prev:before {
  background-image: url('/images/icon/arrow-prev.svg');
  left: 12px;
}

::v-deep .slick-arrow{
  background: rgba(0,0,0,.5);
  backdrop-filter: blur(3px);
  width: 70px;
  height: 100%;
  z-index: 2;
}

::v-deep .carousel-container .slick-arrow.slick-next{
  right: 0px;
}

::v-deep .carousel-container .slick-arrow.slick-prev{
  left: 0px;
}

@media screen and (max-width: 1100), 
    screen and (min-height: 1000) {
    .slick-slider{
        height: 40vh;
    }

    .carousel-image-container{
        height: 40vh;
    }
}

@media (max-width: $tablet) {
    .details{
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin: 80px auto 40px;
        span{
            font-family: poppins, sans-serif;
            font-weight: 275;
            font-size: 24px;
            line-height: 31px;
            letter-spacing: 0.07em;
            text-transform: uppercase;
        }
        .tag{
            margin-right: 0;
            margin-bottom: 10px;
            padding: 9px 20px 5px
        }
    }
}

@media (max-width: $mobile) {
    .address{
        margin: 10px;
        margin-top: 85px;
        h1{
            font-size: 40px;
        }
        h5{
            margin: 20px 0;
        }
    }
    .propery-images{
        display: block;
        .image-container{
            margin: 20px 0;
        }
        .image-container img{
            width: 100%;
            height: auto;
        }
    }
    .details{
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin: 80px auto 40px;
        span{
            font-family: poppins, sans-serif;
            font-weight: 275;
            font-size: 24px;
            line-height: 31px;
            letter-spacing: 0.07em;
            text-transform: uppercase;
        }
    }
    .description{
        margin: 60px 40px;
        .left{
            display: none;
        }
        .right{
            width: 100%;
        }
    }
    .slick-slider{
        height: 300px;
    }

    .carousel-image-container{
        height: 300px;
    }

    ::v-deep .slick-arrow{
        position: absolute;
        height: 80px;
        width: 60px;
        top: 100%;
    }

    ::v-deep .carousel-container .slick-arrow.slick-next{
        right: 27.5%;
    }

    ::v-deep .carousel-container .slick-arrow.slick-prev{
        left: 27.5%;
    }

    ::v-deep .carousel-container .slick-arrow:before{
        width: 22px;
        height: 41px;
        transform: translateY(-22px);
    }

    ::v-deep .carousel-container .slick-arrow.slick-next:before {
        right: 16px;
    }
    ::v-deep .carousel-container .slick-arrow.slick-prev:before {
        left: 16px;
    }
}
</style>